import request from '@/utils/request'

export function top1() {
  return request({
    url: '/board/guarantee/selectAllCount',
    method: 'get',
  })
}

export function top2() {
  return request({
    url: '/board/guarantee/selectToRepay',
    method: 'get',
  })
}

export function top3() {
  return request({
    url: '/board/guarantee/selectThisYear',
    method: 'get',
  })
}

export function top4() {
  return request({
    url: '/board/guarantee/selectAmountCount',
    method: 'get',
  })
}

export function top5() {
  return request({
    url: '/board/guarantee/selectRateCount',
    method: 'get',
  })
}

export function top6() {
  return request({
    url: '/board/guarantee/selectNextMonthEnd',
    method: 'get',
  })
}

export function addBankList() {
  return request({
    url: '/board/guarantee/selectBankCount',
    method: 'get',
  })
}

export function addAreaList() {
  return request({
    url: '/board/guarantee/selectAreaCount',
    method: 'get',
  })
}

export function getProduct() {
  return request({
    url: '/board/guarantee/selectProductCount',
    method: 'get',
  })
}
